/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { Fragment } from "react"

import SEO from "../components/Seo"

const upcoming = [
  "User acounts (free and paid)",
  "Custom study guides",
  "Ad-free viewing",
  "Grammar lessons",
  "Pronunciation lessons",
  "Community forum",
  "Collaborative study",
  "Mobile companion app",
]

// const features = [
//   {
//     label: "Vocabulary",
//     text: "Learn hundreds of new words",
//     icon: "",
//   },
//   { label: "Travel Phrases", text: "Study conversational phrases", icon: "" },
//   {
//     label: "Conjugations",
//     text: `Conjugate common verbs`,
//     icon: "",
//   },
//   {
//     label: "Pronunciation",
//     text: "Listen to each term's audio",
//     icon: "",
//   },
//   { label: "Flashcards", text: "Test your new knowledge", icon: "" },
//   { label: "Community Sourced", text: "Contribute to the guide", icon: "" },
// ]

const AboutPage = () => (
  <Fragment>
    <SEO title="About" />
    <div sx={{ variant: "contentPage" }}>
      <h1>About Us</h1>
      {/* <section
        sx={{ textAlign: "center", p: ["50px 20px", "80px 20px 120px"] }}
        // maxWidth={1080}
      >
        <h2 sx={{ mb: 10, fontSize: 38, color: "primary" }}>
          Upgrade Your {process.env.GATSBY_LANGUAGE} Skills
        </h2>
        <p sx={{ fontSize: 24, color: "#545454" }}>
          Your Free, Interactive Study Guide
        </p>
        <div
          sx={{
            display: "grid",
            gridColumnGap: 50,
            gridRowGap: 70,
            mt: 90,
            gridTemplateColumns: ["1fr", "repeat(3, 1fr)"],
          }}
        >
          {features.map(({ label, icon, text }) => (
            <div key={label} sx={{ display: "flex", alignItems: "center" }}>
              <div
                sx={{
                  height: 75,
                  width: 75,
                  borderRadius: "50%",
                  bg: "gainsboro",
                }}
              />
              <div sx={{ display: "block", textAlign: "left", ml: "20px" }}>
                <h4 sx={{ fontSize: 23 }}>{label}</h4>
                <p sx={{ color: "#656565" }}>{text}</p>
              </div>
            </div>
          ))}
        </div>
      </section> */}
      <section>
        <h2>Our Mission</h2>
        <p>
          This site is designed to pool together helpful vocabulary, phrases,
          verb conjugations, and language resources that help you improve your{" "}
          {process.env.GATSBY_LANGUAGE} skills.
        </p>
        <p>
          Our goal is to equip you for real world conversations as well as
          classroom language studies, so we have developed hundreds of posts
          that cover the majority of topics you might encounter. While not
          exactly a crash course in {process.env.GATSBY_LANGUAGE}, you can use
          this site like an interactive reference tool or a language
          cheat-sheet.
        </p>
      </section>
      <section>
        <h2>Who We Are</h2>
        <p>
          We’re a small team of designers and web developers who are passionate
          about learning languages. We teamed up with a few of our{" "}
          {process.env.GATSBY_LANGUAGE} speaking friends to create this
          interactive study guide.
        </p>
        <p>
          On this site you will find common verb conjugations, vocabulary
          categorized by topic, real-world phrases, and much more. Most of our
          content is supplemented by interactive flashcards (super useful for
          studying) and an audio tool to help you learn proper pronunciation.
        </p>
        <p>
          Our plan is to build many more websites like this one, so stay tuned.
          If you have any language requests or if you identify ways that we can
          improve this website, please <Link to="/contact"> contact us!</Link>
        </p>
      </section>
      <FeatureSection>
        <h2>Upcoming Features</h2>
        <p>
          We are hard at work gathering feedback and building new features into
          this site and the entire{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://languageguide.app"
          >
            Language Guide family
          </a>
          . Soon we will introduce:
        </p>
        <ul sx={{ columns: [1, 2] }}>
          {upcoming.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </FeatureSection>
    </div>
  </Fragment>
)

const FeatureSection = styled.section`
  background: #fbfbfb;
  border: 1px solid #dcdcdc;
  padding: 30px 40px 50px;
  margin-top: 60px !important;

  ul {
    margin-left: 20px;
    line-height: 2;
  }

  p,
  li {
    color: #5a5a5a;
    font-size: 19px;
    font-family: Avenir Next, sans-serif;
    list-style-type: disc;
  }
`

export default AboutPage
